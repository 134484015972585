<script setup>
import { onMounted, ref } from 'vue';
import { vMaska } from 'maska';

defineProps({
    modelValue: {
        type: [String, Number, null],
        required: false,
        default: '',
    },
    name: {
        type: String,
        default: null,
    },
    maskOptions: {
        type: [Object, Boolean],
        default: false,
    },
});

const emit = defineEmits([
    'update:modelValue',
    'focus',
    'blur',
    'input',
    'change',
    'keyup',
    'keydown',
    'maska',
]);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({
    get input() {
        return input.value;
    },
    focus: () => input.value.focus(),
});

const handleInput = (event) => {
    emit('update:modelValue', event.target.value);
    emit('input', event);
};
</script>

<template>
    <input
        v-bind="{ ...$attrs }"
        ref="input"
        v-maska:[maskOptions]
        class="mt-2 block !w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-1 focus:ring-inset focus:ring-gray-400 disabled:bg-gray-100 disabled:opacity-70 disabled:shadow-none sm:text-sm sm:leading-6"
        :value="modelValue"
        @input="handleInput"
        @keydown="(e) => $emit('keydown', e)"
        @maska="$emit('maska', $event)"
    />
</template>
